﻿@import 'colors';

.header {
    height: 90px;
    top: 60px;
    background: #fff;

    @media(min-width:768px) {
        top: 30px;

        .logo {
            &.large-logo {
                width: 175px;
                height: 50px;

                a {
                    width: 175px;
                }

                img {
                    padding: 6px 3px 3px 3px;
                }
            }
        }
    }
}


.headerbar {
    background-color: #22417b;
    height: 60px;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 5000;
    display: inline-block;
    color: #fff;
    font-size: 1.5rem;



    .links {
        text-align: right;
        padding-right: 18px;
        padding-top: 2px;

        a {
            color: white;
            font-family: 'Open Sans', sans-serif;
            font-size: 0.95em;
            text-decoration: none;
            padding:0;margin:0;
            padding-left:5px;
            padding-right:5px;
            margin-left:0;margin-right:0;
            border-right:1px solid #fff;
            display:inline-block;
            line-height:1;
            &:last-child{border:none;}
            &.phone {
                font-weight: bold;
                text-decoration: none !important;
                font-size: 1.8rem;
                width: 100%;
                display: block;
                margin-top: 10px;
                border:none;
            }

            &.underline, &:hover {
                text-decoration: underline;
            }
        }
    }

    @media(min-width:768px) {
        height: 30px;

        .links {
            a {
                &.phone {
                    font-size: 1.4rem;
                    width: auto;
                    display: inline-block;
                    margin-top: 0;
                }
            }
        }
    }
}

.full-intro .header {
    position: fixed;
}

.header, .header.sticky {
    .navigation > ul > li {
        a {
            color: black;

            &:hover {
                color: $orange !important;
            }

            @media(min-width:728px) {
                padding: 41px 16px;
            }
        }

        &.active > a {
            color: $orange;
        }
        a.btn-orange { color: #fff !important;margin-top:20px;
                &:hover { color: #efefef !important;
                }
            }
        @media (min-width:728px) {
            a.btn-orange { padding: 10px; margin-top: 30px;
                &:hover { 
                }
            }
        }
    }
}
.navigation > ul > li > ul > li.active > a { color:$orange;}
