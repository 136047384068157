﻿$grey : #919191;
$lightgrey : #f4f2f2;
$medgrey: #7F7F7F ;
$dark-grey: #636363; 
$light-green: #05ca3f;
$light-green-hover: #039C30;
$brightblue: #3377b7 ;
$darkblue: #22417b;
$blueblack: #11253f; 
$orange: #e65625;
$darkorange: #cc481b;
$white: #ffffff;


.grey-background { background-color: $grey;color: #fff;}
.light-grey-background { background-color: $lightgrey;}
.dark-blue-background { background-color:$darkblue; }
.green-background {background-color:$light-green;}
.orange-background {background-color:$orange;}


.dark-blue-text {color:$darkblue;}
.BlueText {color:$brightblue;}
.white-txt {color:$white;&a:hover{color:#efefef;}}
.orange-txt {color:$orange;}
.dark-grey-txt {color:$dark-grey;}


.gold-gradient {
    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 1%, #d2b636 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 1%,#d2b636 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #ffffff 1%,#d2b636 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d2b636',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.blue-grey-gradient {
    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 1%, #357a99 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 1%,#357a99 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #ffffff 1%,#357a99 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#357a99',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.orange-gradient {
    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 1%, #ff9000 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 1%,#ff9000 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #ffffff 1%,#ff9000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ff9000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.red-gradient {
    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 1%, #a7360a 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 1%,#a7360a 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #ffffff 1%,#a7360a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a7360a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.green-gradient {
    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 1%, #1db78a 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 1%,#1db78a 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #ffffff 1%,#1db78a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#1db78a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}


@media (min-width:768px){
.green-border {border-right:1px solid $light-green;}
.orange-border {border-right:1px solid $orange;}
.dark-blue-border {border-right:1px solid $darkblue;}
}