@import 'colors';
@import 'buttons';
@import 'navStyles';

$font1: 'Roboto Condensed', sans-serif;
$font2: 'Open Sans', sans-serif;

html {font-size:10px;}
body{
    font-family: $font2;font-weight:200;
} 

h1,h2,h3,h4,h5,h6 {font-weight:400;font-family:$font1;
    -webkit-padding-start:0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
}

ul {    -webkit-padding-start: 0;}
ul li{text-align:left !important;}
a {color:$brightblue;}

.ptb-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.sectionText ul {
    -webkit-padding-start:20px;
}


.rotating {font-weight:900;}

.centerAlign ul {margin:0 auto;display:inline-block;width:auto;}

.sectionHeadline {margin-bottom:0; margin-top:30px; font-weight:400;font-family: 'Roboto Condensed', sans-serif;}
.bold {font-weight:bold;}
p{padding:0;}

.full-screen-intro {&.short-intro{ height:500px !important; margin-top:126px;}}
.inner-intro {min-height:740px;margin-top:125px;}
.inner-intro.inner-intro.inner-intro-small {
    margin-top: 125px;
    min-height:550px;
    .title{padding-top:0;
           &.JustHeadline{padding-top:150px;}
    }

    .sectionHeadline{margin-top:100px;}
    @media (max-width:420px) {
        height: auto;
    }
}

.home-header {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 4.4rem;
    font-weight: 500;
    max-width: 600px;
    text-align: center;
    line-height: 1.1;
    margin: 0 auto;
}
.header {position:fixed;background-color:#fff !important;height:96px;
         .logo{ a{margin-top:23px;}}
}

.navigation > ul > li a{line-height:1;font-size:1.4rem;}
.navigation .nav-has-sub  {padding-bottom:1px;}

.header.sticky .navigation ul ul li a, .header .navigation ul ul li a, .navigation ul ul li a {
    font-size:1.4rem;
}


.dropdown-arrow {
    position: relative;
    z-index:100;
    cursor:pointer;
}

.dropdown-arrow:after {
    content: "\f0d7";
    font-family:FontAwesome;
    position: absolute;
    right: 10px;
    font-size:30px;
    top: 12px;
    color:#888;
    z-index:50;
    pointer-events:none;
}
.dropdown-arrow:hover:after {
    color:$orange;
}


@media (min-width:1001px) {
    .maxSet {
        width: 1000px;
        max-width: 100%;
        margin: auto;
    }
}

.lead {font-size:3rem;font-weight:500;margin-bottom:20px;display:block;}
.sectionImage {margin-bottom:20px;margin-left:auto;margin-right:auto;display:block;
               &.below{margin-top:50px;}
}

.rightAlign {text-align:right;}
.leftAlign {text-align:left;}
.centerAlign {text-align:center;}
.justifyAlign {text-align:justify;}


.size-1 {font-size:1.2rem;line-height:1.2rem; p{font-size:1.2rem;line-height:1.2rem;}}
.size-2 {font-size:1.4rem;line-height:2rem; p{font-size:1.4rem;line-height:2rem;}}
.size-3 {font-size:1.6rem;line-height:2.4rem; p{font-size:1.6rem;line-height:2.4rem;}}
.size-4 {font-size:1.8rem;line-height:3rem; p{font-size:1.8rem;line-height:3rem;}}
.size-5 {font-size:2.4rem;line-height:3.6rem; p{font-size:2.4rem;line-height:3.6rem;}}
.size-6 {font-size:3.4rem;line-height:4.2rem; p{font-size:3.4rem;line-height:4.2rem;}}
.size-7 {font-size:4.8rem;line-height:5rem; p{font-size:4.2rem;line-height:4.8rem;}}
.size-8 {font-size:5.4rem;line-height:5.6rem; p{font-size:5.4rem;line-height:5.4rem;}}



.ptb {height: 610px;}


.partners-header {
    /*border-left: 2px solid;*/
    padding-left: 10px;
    margin-left: 10px;
    display:table-cell;
    line-height:50px;
    .sectionHeadline { margin-top:0; margin-bottom: 0; }
    .spacer {display:none;}
}

.bordered .sectionHeadline {border-top:1px solid #000; border-bottom:1px solid #000;padding-top:10px; padding-bottom:10px;}

.partners-header-bordered {
    border-top:1px solid $darkblue;
    border-bottom:1px solid $darkblue;
    width:auto;
    display:inline-block;
    /*text-transform:uppercase;*/
    color:$darkblue !important; 
    margin-bottom:30px;
    padding-top:5px;
    padding-bottom:5px;
    a{color:$darkblue;display:block;}
    .sectionHeadline{margin-bottom:0;margin-top:0;}
    .spacer {display:none;}
}

.partner-element {
    box-sizing:border-box;/*padding-top:40px;padding-bottom:40px;*/
    margin-right:auto;margin-left:auto;max-width:100%; 
    p{font-weight:400;margin-left:auto;margin-right:auto;width:300px;max-width:80%;min-height:60px;}
    .checklist {margin-left:auto;margin-right:auto;width:100%;}
    .flex-divider{display:flex;}
    &.col-sm-6 p{width:100%;max-width:100%;}
    @media (min-width:768px){
        min-height:200px;
    }

}


.checklist {list-style:none;text-align:left;font-size:1.6rem; line-height:3rem;font-weight:400;margin-top:20px;-webkit-padding-start:20px;
            i{color:$orange;margin-right:6px;font-size:2.4rem;}
}

.page-icon { text-align:center;display:initial;
                 .iconHolder{height:125px;}
                 .partners-header {margin-left:0;padding-left:0;
                                   &.left-icon{line-height:50px;vertical-align:middle;margin-top:0;padding-top:0; 
                                               .sectionHeadline{line-height:50px;vertical-align:middle;padding-left:10px;border-left:2px solid;margin-left:10px;}
                                   }
                                   &.top-icon{padding-bottom:15px;}
                 }
                 }
.page-icon img{
                   &.left-icon{height:50px;display:inline-block;clear:none;vertical-align:top;} 
                   &.top-icon{margin-bottom:20px;margin-top:40px;width:auto;max-height:120px;clear:both;display:block;margin-left:auto;margin-right:auto; }
}
.page-icon i{border:4px solid $darkblue;color:$darkblue;
                 &.orange-icon{ color:$orange;border-color:$orange;}
                 &.dkblue-icon{ color:$darkblue;border-color:$darkblue;}
                 &.left-icon{float:none;display:table-cell;padding-right:10px;border:none;border-radius:0;font-size:50px;line-height:50px;}
                 &.top-icon{padding:10px;border-radius:80px;height:80px;width:80px;margin:0 auto 20px auto;display:block;text-align:center;padding-right:5px;padding-left:5px;clear:both;font-size:50px;}
}


.imageHolder {
position:relative;
display:block;
}
.positionable-link {
    margin:0 50%; position:absolute;
    text-align:left;
    a{color:$orange;font-size:1.6rem;}
    h4{margin:0;}
    @media (max-width:768px){
display:none;
    }
}


.footer {
    background-color: #22417b;
    color:#fff;
    .footer-info{ 
        text-align:left;
        p{ color:#fff;}
        img.footer-image {margin-top:20px;margin-bottom:10px;}
        @media (min-width:768px){}
    }
    a{color:#fff;}
    .copyright{color:$medgrey;}
    .social{margin-top:55px;
            i{background-color:$orange;padding:10px;border-radius:20px;width:40px;color:$darkblue;text-align:center;}
    }
    .footer-title {color:$orange;font-size:2.0rem;font-family:'Roboto Condensed', sans-serif;font-weight:500;margin-bottom:30px;}
}


/*.subtitle {margin-top:30px;border-top:1px solid #fff;padding:30px;}*/



.testimonial-headline {text-align:center;margin-bottom:40px;}
.testimonial {.lead {font-style:italic;font-size:2rem;line-height:3rem;}
              .quote-author{font-size:2.4rem;line-height:3.6rem;text-align:right;}
}


.multiColumnSection {
    .container {width:auto;}
}




@media (min-width:992px) {
    .wide-container {
        width: 990px;
        margin: 0 auto;
    }

    .column5 {
        width: 33.33333333%;
        max-width: 100%;
        margin: 30px auto;
        padding-left: 25px;
        padding-right: 25px;
        vertical-align: top;
    }
}

@media (min-width:1400px){
    .column5 {width:20%;display:inline-block; max-width:280px;margin:30px auto;padding-left:15px;padding-right:15px;vertical-align:top;}
    .column5show {display:block !important;}
    .wide-container {
        width: 1400px;
    }
    .logos-container { width:100%; }

}


.background--dark .header, .background--dark .header a, .background--dark .header .side-menu-btn ul li a.right-icon, .background--dark .header .mobile-menu-icon {
    color: $darkblue;
}




.btn.centerAlign {display:table;margin-left:auto;margin-right:auto;}


.mobile-menu-icon {font-size:3rem;}

.sectionText {}
.sectionText li {
    @media(max-width:768px){
        margin-left:25px;
    }
    a{ color:$brightblue;}

}

#HeaderVideo {
    position: absolute;
    top: 16%;
    left: 6%;
    width:420px;
    height:315px;
    padding:0;
    margin-top:125px;
    max-width:100%;
    @media (max-width:320px){left:auto;margin:0;}
}

.videoInset > iframe {
    margin:0 auto;display:block;max-width:560px;height:315px;width:100%;
}
.videoInset {
    margin-top:40px;
    .videoCaption{text-align:center;font-style:italic;font-size:1.6rem;line-height:3rem;margin-top:5px;}
}

.centered-line {
    height: 1px;
    border-top: 1px solid white;
    width: 310px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;

}


.subhead, .sectionSubHead {
    font-family: 'Open Sans', sans-serif;font-weight:300;margin-bottom:40px;
}


.sidebarLayout{
    .narrowSection {@media(min-width: 610px){width:600px;margin:0 auto;} }
}
.narrowSection { @media(min-width: 810px){width:800px;margin:0 auto;}}

.narrowText {max-width:800px;margin-left:auto;margin-right:auto;}

.videoInset {margin-bottom:30px;padding-left:40px;padding-right:40px;}


.widget-columns {
    @media(max-width:991px){border:none;
            .ptb-sm-60{ padding-top:10px;padding-bottom:10px;}
    }
    @media(min-width:768px) {min-height:150px;margin-bottom:30px;margin-top:30px;
        .ptb-sm-60{ padding-top:10px;padding-bottom:10px;}
    }
}

.ptb-0 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;

    @media (min-width:768px) {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.header-buttons {margin-top:40px;}
.phone {margin-top:20px; font-weight:600;}

.anchor {position:relative; top:-100px;display:block; }




// OWL CAROUSEL STYLES ------------------------------------------------------------------------------------------- 

.nf-carousel-theme .owl-controls .owl-buttons .owl-prev, .nf-carousel-theme .owl-controls .owl-buttons .owl-next {
    font-size:7rem;width:80px;height:80px;background:none;border:2px solid #fff; border-radius:40px;color:#fff;line-height:6rem;text-align:center;vertical-align:middle;    
    
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
    &:hover{color:#666;border-color:#666;}
    @media(max-width:960px){display:none;}
}
.light-grey-background .nf-carousel-theme .owl-controls .owl-buttons .owl-prev, .light-grey-background .nf-carousel-theme .owl-controls .owl-buttons .owl-next {
    color: #666; border-color:#666;
    &:hover {color:$orange; border-color:$orange;}
}
.owl-carousel .owl-controls .owl-page span {
    border: none;
    background-color: #efefef;
}
.owl-carousel .owl-controls .owl-page.active span {border:none;background-color:#666;}

.light-grey-background .owl-carousel .owl-controls .owl-page span {
    background-color:#666;
}
.light-grey-background .owl-carousel .owl-controls .owl-page.active span { background-color:$orange;}
.dropdown-links {
    text-align: center;
    font-size: 1.8rem;
    margin: 10px auto;
    display: block;
    font-weight: 600;

    select {
        font-size: 1.8rem;
        padding: 18px 60px;
        width: 300px;
        max-width: 100%;
        border-radius: 8px;
        margin-left: 20px;
        margin-right: 20px;
        background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
        background-position: calc(100% - 19px) calc(2.5rem + 2px), calc(100% - 15px) calc(2.5rem + 2px);
        background-size: 5px 5px, 6px 6px;
        background-repeat: no-repeat;
    }
}



//Client Logos Section Styles ---------------------------------------------------------------------------

.client-logo {
    margin-top: 0;
    height:auto;
    .client-logo-container {
        height: 100%;
        line-height: 80px;

        img {
            height: auto;
            /*max-height: 60px;*/
            vertical-align: middle;
        }
    }
}


.client-logos {
    padding-left:15px;
    padding-right:15px;
    box-sizing:border-box;
    display:inline-block;
    width:98%;
    vertical-align:middle;
    img{max-width:100%; width:auto;margin:0 auto;}

}

.client-logo-row {display:inline;}


@media (min-width:768px){
.client-logo-1 {width:100%;}
.client-logo-2 {width:50%;}
.client-logo-3 {width:33.33%;}
.client-logo-4 {width:25%;}
.client-logo-5 {width:20%;}
.client-logo-6 {width:16.67%;}
.client-logo-7 {width:14.28%;}
.client-logo-8 {width:12.5%;}
.client-logo-9 {width:11.11%;}
.client-logo-10 {width:10%;}
}


.dividerSection {
    height:100px;
    width:100px;
    border-radius:50px;
    margin:0 auto;
    text-align:center;
    line-height:100px;
    font-size:50px;
    font-weight:800;
    top:-50px;
    position:relative;
}

.dividerParent {height:0;}

.multiColumnSection {
    .widget-columns{
        .sectionHeadline {margin-top:0;}
        .clearfix {display:none;}
    }
}



.sideBarBody {
    .header{height:200px;
        @media (max-width:768px){
            .logo { position:relative;float:none; height:60px; width:100%;
                &.pull-right{float:none !important;}
                img{max-width:175px;display:block;margin:23px auto 0 auto;}
                a {width:auto;}
                a img{ position:relative;}
        }

        }
            @media(min-width:768px){ height:96px;
                                     .logo { &.pull-right{ float:right !important;margin-top:20px;}}
            }
    }

}

@media (min-width: 1400px) {
    .sideBarBody {
        .container-fluid {
            max-width: 1400px;
        }
    }
}

.sidebarLayout {
    margin-top:326px;
    border-top: 2px solid $darkblue;
    width:1200px; max-width:100%;margin-left:auto;margin-right:auto;
    .container {width:auto;}
    .ptb {padding-top:20px;}
    .nf-carousel-theme .owl-controls .owl-buttons .owl-prev, .nf-carousel-theme .owl-controls .owl-buttons .owl-next { color:#666;border-color:#666;font-size:30px;width:30px;height:30px;border-radius:15px;line-height:30px;
                                                                                                                       i{position:relative;bottom:3px;}
    }
    .sidebarFormWrapper {margin-bottom:30px;}
    .item{padding-left:30px;padding-right:30px;}
    @media(min-width:768px){margin-top: 126px;
}
    @media(min-width:1400px){ width:1400px;}
}




.smallIcon {height:90px;}
.medIcon {height:120px;}
.page-icon img.top-icon.fullIcon {height:260px;max-height:260px;}
.iconHolder.tall{ height:auto;}


.oversizedImage {width:100%;}
.sectionImage {
    &.smallImage {height:200px;max-width:100%;width:auto; }
    &.mediumImage {height:300px;max-width:100%;width:auto;}
    &.fullWidthImage {width:100%;margin-bottom:0;}
}


#__lpform_Name_icon {display:none;}






.chart-col {width:100%;position:relative;
            &.first-col{display:none;}
            &.active {.chart-row{display:block; text-align:right;padding:5px 10px;height:30px; &.empty{display:none;} .chart-key{display:block;float:left;margin-right:10px;margin-left:10px;font-weight:800;}}}
            &.selected { .best-value{display:block;position:absolute;height:67px;width:67px;right:10px;top:40px;background-image:url("../../img/active-star.png");background-size:contain;}
}
}
.chart-header {text-align:center;background:$darkblue;color:#fff;font-weight:800;}
.chart-topper {text-align:center; font-size:2rem;padding-top:20px;padding-bottom:20px; font-weight:800; .price{font-size:3rem;margin-bottom:5px;} sup{top:-1rem;font-size:1rem;}}
.chart-row {display:none;font-size:1.5rem; font-weight:400;.fa-check{color:$light-green;}}

.chart-Intro{margin-top:0px; margin-bottom:20px; padding-bottom:20px; padding-top:20px; border-radius:15px; color:#fff;font-family: 'Oswald', sans-serif; }
.demo_form_signup {
    margin-right:10px;
    /*margin: 20px auto;
    input {margin-top:20px;}*/
    input{width:100%;}
    input[type=submit]{ width:100%;padding:4px 15px; }
    @media(min-width:1090px){ margin-top:0;}
    .btn {font-family: 'Oswald', sans-serif;}

}
.demo_form_header {
    height: 45px;
    color: #fff;
    font-weight: 800;
    text-align: center;
    font-size: 3rem;
    line-height: 3rem;
    vertical-align: middle;
    position: relative;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
}



@media (min-width:768px){
.chart-box{padding:10px;border-radius:10px; box-shadow:2px 2px 5px black;}

.chart-col {width:16.65%;float:left;box-sizing:border-box; font-family:'Oswald', sans-serif;
            &.first-col{display:block;
                .chart-topper{border:1px solid #fff;height:175px;padding-top:0;}
                .chart-row{font-weight:700;text-align:left;overflow-y:auto; }
                &.active{.chart-row{text-align:left;}}
            }
            &.selected{position:relative;top:-5px;box-shadow:1px 1px 10px black;z-index:100;
                       .best-value{display:block;position:absolute;height:47px;width:47px;right:0;top:0;background-image:url("../../img/active-star.png");background-size:contain;}
            }
            &.active {.chart-row{ text-align:center; .chart-key{display:none;} &.empty{display:block;}}}

}
.chart-header {height:45px;background:$darkblue;color:#fff;font-weight:800;text-align:center;font-size:1.6rem;line-height:3rem;vertical-align:middle;position:relative;padding-bottom:15px;}
.chart-topper {width:100%;height:130px;border:1px solid #666;border-left:none;display:block;padding-top:20px;
               .price{font-size:4rem;color:#fff;font-weight:700;text-align:center;text-shadow:1px 1px 2px #000000;
                      sup{font-size:2rem;top:-1.5rem;}
               }
               .chart-volume {font-size:2rem;text-align:center;color:#fff;text-shadow:1px 1px 2px #000000;font-weight:800;margin-top:15px;}
}
.chart-row {height:30px; border-left:none;border-right:1px solid #999;border-bottom:1px solid #999;padding:5px 10px;line-height:1.9rem;vertical-align:bottom;text-align:center;display:block;
            .chart-key{display:none;}
}


.arrow-wrap{width:50%;position:relative;height:15px;bottom:-1px;}
.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position:absolute;bottom:-1px;
    text-align:center;
    right:-7.5px;
    &.gold{border-bottom: 10px solid #d2b636;}
    &.blue-grey{border-bottom: 10px solid #357a99;}
    &.orange{border-bottom: 10px solid #ff9000;}
    &.red{border-bottom: 10px solid #a7360a; }
    &.green{border-bottom: 10px solid #1db78a; }
    }

}

#contactFormHolder .form-field-wrapper { @media (min-width:768px){ width:49%; float:left; margin-top:5px;
                                                                   &.wide-field{width:100%;float:none;}
                                                                    &.left{margin-right:2%;}
                                                                    textarea{ line-height:1.5rem;height:37px;}
                                         }
                                         
}
.sidebarFormWrapper #contactFormHolder  .form-field-wrapper { width:100%;float:none; margin:10px 0;}




#carousel {
    width: 100%;
    height: 300px;
    display: block;
    margin: 50px auto 50px auto;
    @media(min-width:810px) { width: 800px; }
}

#carousel img {
    display: none; /* hide images until carousel prepares them */
    cursor: pointer; /* not needed if you wrap carousel items in links */
    width:100%;
    @media(min-width:768px) { width: 500px; }
}


