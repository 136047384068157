﻿@import 'colors';

.maxBtn {width:290px;max-width:100%;margin-left:auto;margin-right:auto;}

.btn {
    padding: 20px 60px;
    border-radius: 8px;
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 20px;
    white-space:normal;
}

.btn-green  { background-color: $light-green; border: none; color:#fff;
    &:hover { background-color: $light-green-hover; color:#efefef;}
}

.btn-orange { background-color: $orange; color:#fff;
    &:hover { background-color: $darkorange; color:#efefef;}
}

.btn-dkblue{ background-color:$darkblue; border:none; color:#fff;
             &:hover{ background-color:$blueblack; color:#efefef;}
}

